// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-blog-tsx": () => import("./../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tools-json-formatter-tsx": () => import("./../../src/pages/tools/json-formatter.tsx" /* webpackChunkName: "component---src-pages-tools-json-formatter-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-tag-article-tsx": () => import("./../../src/templates/tag-article.tsx" /* webpackChunkName: "component---src-templates-tag-article-tsx" */)
}

